import { FC } from 'react';
import Image from 'next/image';
import { cx, LinariaClassName } from '@linaria/core';

import * as styles from './styles';

interface Props {
  className?: LinariaClassName;
}

const BoxedSignBW: FC<Props> = ({ className }) => (
  <div
    {...{
      className: cx(styles.boxedSign, className),
    }}
  >
    <Image
      {...{
        src: '/images/sign_black.svg',
        alt: 'fitolio logo',
        layout: 'fixed',
        width: 48,
        height: 48,
      }}
    />
  </div>
);

export default BoxedSignBW;
