import { FC } from 'react';
import { cx, LinariaClassName } from '@linaria/core';

import * as styles from './styles';

interface Props {
  className?: LinariaClassName;
}

const Footer: FC<Props> = ({ className }) => (
  <p
    {...{
      className: cx(styles.container, className),
    }}
  >
    © 2024, fitolio All rights reserved
  </p>
);

export default Footer;
