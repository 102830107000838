import { FC } from 'react';
import Link from 'next/link';
import { cx, LinariaClassName } from '@linaria/core';

import * as styles from './styles';

interface Props {
  className?: LinariaClassName;
}

const HeadingMenuList: FC<Props> = ({ className }) => (
  <nav
    {...{
      className: cx(styles.menu, className),
    }}
  >
    <ul>
      <li>
        <Link
          {...{
            href: '/mentors/become',
          }}
        >
          <a
            {...{
              href: '/mentors/become',
            }}
          >
            Become a creator
          </a>
        </Link>
      </li>

      <li>
        <Link
          {...{
            href: '/help',
          }}
        >
          <a
            {...{
              href: '/help',
            }}
          >
            Support
          </a>
        </Link>
      </li>

      <li>
        <Link
          {...{
            href: '/account',
          }}
        >
          <a
            {...{
              href: '/account',
              className: styles.button,
            }}
          >
            Login
          </a>
        </Link>
      </li>
    </ul>
  </nav>
);

export default HeadingMenuList;
